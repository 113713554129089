





















import { Component, Vue } from 'vue-property-decorator';
import { getComponent,getView } from '@/utils/helpers';
import workspaceModule from '@/store/modules/workspaceModule';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    WorkspacesNavbar: () => getComponent('common/WorkspacesNavbar'),
	Schedule: () => getView('Schedule'),
	WellTestSchedule: () => getView('WellTestSchedule'),
  },
})
export default class Workspaces extends Vue {

  loadingTasqs = false


  get activeWorkspace() {
    return workspaceModule.activeWorkspace;
  }
  async created() {
    this.loadingTasqs = true;
    await workspaceModule.getWorkspaces()
	
    if (this.$route.params.id) {
      workspaceModule.setActiveWorkspace(this.$route.params.id);
 
    }
    this.loadingTasqs = false;
	
  }
}
